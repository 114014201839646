jQuery(document).ready(function($){

    $('.slider-photo').on('init', function(event, slick) {
        $(".slider-photo [data-slick-index='"+slick.currentSlide+"'] video").each(function() { this.play() });
    });

    $('.slider-photo').slick({
        dots: false,
        speed: 300,
        infinite: true,
        autoplay: true,
        fade: true,
        autoplaySpeed: 5000,
        cssEase: 'ease-out',
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-long-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-long-arrow-right"></i></button>',
    });

    $('.slider-photo').on('afterChange', function(event, slick, currentSlide) {
        $(".slider-photo [data-slick-index='"+currentSlide+"'] video").each(function() { this.play() });
    });

    $('.slider-photo').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        $(".slider-photo [data-slick-index='"+currentSlide+"'] video").each(function() { this.pause() });
    });

    $('.slider-portfolio').slick({
        dots: false,
        speed: 300,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        cssEase: 'ease-out',
        arrows: true,
        fade: false,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-long-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-long-arrow-right"></i></button>',
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        appendArrows: '.portfolio__navi',
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
        ],
    });

    $('.slider-reviews').slick({
        dots: true,
        speed: 300,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        cssEase: 'ease-out',
        arrows: false,
        fade: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
    });

    $('.slider-logo').slick({
        dots: false,
        speed: 300,
        infinite: true,
        autoplay: true,
        fade: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplaySpeed: 5000,
        cssEase: 'ease-out',
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ],
    });

});
